import $ from "jquery";
import { Home } from "./lib/common";
import { autoKana } from "./lib/autoKana";
import { circle } from "./lib/circle";
import { templateElementScrollAction } from "./lib/templateElementScrollAction";
import { headerSearchBtn } from "./lib/headerSearchBtn";

jQuery(function ($) {
    // ツールバーのテンプレートファイル名をクリックで要素へスクロールする
    templateElementScrollAction();

    // キーワード検索のフォームの時
    // headerSearchBtn()
});