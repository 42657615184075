const Home = {
    isMobile: function () {
        return jQuery(window).width() <= 768;
    },
    pageScrollLink: function () {
        const headerHight = jQuery("header").height();

        jQuery('a[href^="#"]').on('click', function (e) {
            const href = jQuery(this).attr("href");
            const target = jQuery(href == "#" || href == "" ? 'html' : href);
            const position = target.offset().top - headerHight;

            jQuery.when(
                jQuery("html, body").animate({
                    scrollTop: position
                }, 400, "swing"),
                e.preventDefault(),
            ).done(function () {
                var diff = target.offset().top - headerHight;
                if (diff === position) {
                } else {
                    jQuery("html, body").animate({
                        scrollTop: diff
                    }, 10, "swing");
                }
            });
        });
    },
    questionaryHiddenPost: function () {
        const questionaryMoreBtn = jQuery("#questionaryMoreBtn");

        if (questionaryMoreBtn.length === 1) {
            const listQuestionary = jQuery("#listQuestionary");

            questionaryMoreBtn.on('click', function () {
                listQuestionary.children(".cts.hidden").removeClass("hidden");
                questionaryMoreBtn.fadeOut();
            });
        }
    },
    getImageAltText: function (trigger) {
        return trigger.querySelector('img').getAttribute('alt');
    },
    initializePhotoGallery: function (selector) {
        const photoGallery = document.querySelectorAll(selector);
        if (photoGallery.length > 0) {
            new LuminousGallery(photoGallery, {}, {
                caption: Home.getImageAltText
            });
        }
    },
    initializePhotoSingle: function (selector) {
        const luminousTrigger = document.querySelector(selector);
        if (luminousTrigger === null) return false;

        new Luminous(document.querySelector('.photo-single'), {
            caption: Home.getImageAltText
        });
    },
    jsAreaAccordionTab: function () {
        if (jQuery('.jsAreaAccordionTab').length === 0) return false;

        const jsAreaAccordionTab = jQuery('.jsAreaAccordionTab');
        jsAreaAccordionTab.on('click', function () {
            jQuery(this).children('.jsAreaAccordionHeadline').toggleClass('on');
            jQuery(this).children('.jsAreaAccordionList').stop().slideToggle();
        });
    },
    flowerAltarGallerySlider: function () {
        if (jQuery(".gallerySlider .thumbnail_slider").length === 0) return false;
        const maiSlider = jQuery(".gallerySlider .main_slider");
        const thumbnailSlider = jQuery(".gallerySlider .thumbnail_slider");

        maiSlider.slick({
            autoplay: true,
            dots: false,
            arrows: true,
            asNavFor: ".thumbnail_slider",
        });

        // サムネイルスライダー初期値を設定する。現枚数と総枚数を表示する
        thumbnailSlider.on('init', function (event, slick) {
            jQuery(this).prepend('<div class="slick-num"><span class="now-count"></span> / <span class="all-count"></span></div>');
            jQuery(".now-count").text(slick.currentSlide + 1);
            jQuery(".all-count").text(slick.slideCount);
        });

        thumbnailSlider.slick({
            slidesToShow: 7,
            focusOnSelect: true,
            arrows: true,
            asNavFor: ".main_slider",
            pauseOnFocus: false,
            pauseOnHover: false,
            responsive: [
                {
                    breakpoint: 769,
                    settings: {
                        slidesToShow: 4,
                    },
                },
            ],
            prevArrow: '<button class="slide-arrow prev-arrow"></button>',
            nextArrow: '<button class="slide-arrow next-arrow"></button>'
        });

        // 次、前ボタンをクリック後、現枚数と総枚数を変更する
        thumbnailSlider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            jQuery(".now-count").text(nextSlide + 1);
        });
    },
    googleMapsJavaScriptApi: function (apiKey) {
        const api = (g => { var h, a, k, p = "The Google Maps JavaScript API", c = "google", l = "importLibrary", q = "__ib__", m = document, b = window; b = b[c] || (b[c] = {}); var d = b.maps || (b.maps = {}), r = new Set, e = new URLSearchParams, u = () => h || (h = new Promise(async (f, n) => { await (a = m.createElement("script")); e.set("libraries", [...r] + ""); for (k in g) e.set(k.replace(/[A-Z]/g, t => "_" + t[0].toLowerCase()), g[k]); e.set("callback", c + ".maps." + q); a.src = `https://maps.${c}apis.com/maps/api/js?` + e; d[q] = f; a.onerror = () => h = n(Error(p + " could not load.")); a.nonce = m.querySelector("script[nonce]")?.nonce || ""; m.head.append(a) })); d[l] ? console.warn(p + " only loads once. Ignoring:", g) : d[l] = (f, ...n) => r.add(f) && u().then(() => d[l](f, ...n)) })({ key: apiKey, });

        return api;
    },
    postSearchGoogleMapPins: function () {
        if (typeof postSearchMapData === 'undefined') return false;

        let map;
        const apiKey = postSearchMapData.apiKey;
        const lat = parseFloat(postSearchMapData.lat);
        const lng = parseFloat(postSearchMapData.lng);
        const api = Home.googleMapsJavaScriptApi(apiKey);

        async function initMap() {
            const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
            const beachFlagImg = document.createElement("img");
            beachFlagImg.src = imgPath.url + "icn_map.svg";
            beachFlagImg.style.width = '32px';
            beachFlagImg.style.height = '46px';

            map = new google.maps.Map(document.getElementById('map'), {
                center: {
                    lat: lat,
                    lng: lng
                },
                zoom: 14,
                mapId: google.maps.MapTypeId.ROADMAP,
                mapTypeControl: 0,
            });

            const marker = new google.maps.marker.AdvancedMarkerElement({
                map,
                position: new google.maps.LatLng(lat, lng),
                content: beachFlagImg,
            });
        }
        initMap();
    },
    areaSearchGoogleMapPins: function () {
        if (typeof areaSearchMapData === 'undefined' || $('#map').length === 0) return false;

        const apiKey = areaSearchMapData[0]['apiKey'];
        const api = Home.googleMapsJavaScriptApi(apiKey);
        let map;
        let marker = [];
        let infoWindow = [];
        let bounds = '';
        let markerData = areaSearchMapData[1];

        const contentString = (index, data) => {
            const facilityList = data['icons'].map(v => `<li> <i class="icn"><img src="${imgPath.url}${v['icon']}" alt=""></i> <span>${v['name']}</span> </li>`).join('');
            return facilityList;
        }

        async function initMap() {
            const { Map } = await google.maps.importLibrary("maps");
            const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
            bounds = new google.maps.LatLngBounds();
            infoWindow = new google.maps.InfoWindow();

            // 斎場が1つの場合、zoomが期待通りの挙動にならないため、分岐する
            if (markerData.length > 1) {
                map = new google.maps.Map(document.getElementById('map'), {
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    mapId: "DEMO_MAP_ID",
                    mapTypeControl: 0,
                    fullscreenControl: 0,
                });
            } else {
                map = new google.maps.Map(document.getElementById('map'), {
                    zoom: 14,
                    center: { lat: parseFloat(markerData[0]['lat']), lng: parseFloat(markerData[0]['lng']) },
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    mapId: "DEMO_MAP_ID",
                    mapTypeControl: 0,
                    fullscreenControl: 0,
                });
            }

            markerData.map((item, index) => {
                const beachFlagImg = document.createElement("img");
                beachFlagImg.src = imgPath.url + "icn_map.svg";
                beachFlagImg.style.width = '32px';
                beachFlagImg.style.height = '46px';

                marker = new google.maps.marker.AdvancedMarkerElement({
                    map,
                    position: new google.maps.LatLng(parseFloat(item['lat']), parseFloat(item['lng'])),
                    content: beachFlagImg,
                });
                bounds.extend(marker.position);
                google.maps.event.addListener(marker, 'click', ((marker, index) => {
                    return function () {
                        const customContent = `
                            <div class="google-map-info-window">
                                <h2 class="google-map-info-window__title"><a href="${item['post_url']}">${item['post_title']}</a></h2>
                                <div class="google-map-info-window__content">
                                    <p>${item['content']}</p>
                                </div>
                                <ul class="google-map-info-window__list list_option">
                                    ${contentString(index, item)}
                                </ul>
                            </div>
                        `;
                        infoWindow.setContent(customContent);
                        infoWindow.open(map, marker)
                    }
                })(marker, index))
            });

            if (markerData.length > 1) {
                map.fitBounds(bounds);
            }
        };
        initMap();
    },
    searchAreaSaijouAccordion: function () {
        let accordionDetails = '.js-details';
        let accordionSummary = '.js-summary';
        let accordionContent = '.js-content';

        if (jQuery(accordionSummary).length === 0) return false;

        jQuery(accordionSummary).each(function () {
            jQuery(this).on("click", function (event) {
                event.preventDefault();
                jQuery(this).toggleClass("is-active");
                if (jQuery(this).parent(jQuery(accordionDetails)).attr("open")) {
                    jQuery(this).parent(jQuery(accordionDetails)).removeAttr("open");
                    jQuery(this).next(accordionContent).removeClass("open");

                } else {
                    jQuery(this).parent(jQuery(accordionDetails)).attr("open", "true");
                    jQuery(this).next(accordionContent).addClass("open")
                }
            });
        });
    },
    headerHamburgerMenuAction: function () {
        const headerHamburgerMenu = $('#headerHamburgerMenu');

        if (headerHamburgerMenu.length === 0) return false;

        headerHamburgerMenu.on('click', function () {
            const target = $(this);
            const spNavGlobalMenu = $('#spNavGlobalMenu');

            if (target.hasClass('open')) {
                target.removeClass('open');
                spNavGlobalMenu.removeClass('open');
                target.text('メニュー');
            } else {
                target.addClass('open');
                spNavGlobalMenu.addClass('open');
                target.text('閉じる');
            }
        });
    },
    pcHeaderNavAction: function () {
        jQuery(".headerNavItem").mouseover(function () {
            let container = jQuery("#sitecontain");
            if (!container.hasClass("over")) {
                jQuery("#sitecontain").addClass("over");
            }
        }).mouseout(function () {
            let container = jQuery("#sitecontain");
            if (container.hasClass("over")) {
                jQuery("#sitecontain").removeClass("over");
            }
        });
    },
    commonMorePostBtnAction: function () {
        const commonMorePostBtn = $('.commonMorePostBtn');
        if (commonMorePostBtn.length === 0) return false;

        $('.commonMorePostBtn').on('click', function () {
            console.log($(this));
            console.log($(this).prev());
            console.log($(this).prev().find('.is-hidden'));

            const targetItem = $(this).prev().find('.is-hidden');

            targetItem.removeClass("is-hidden");
            $(this).addClass('is-hidden');

        });
    },
    removeHyphenPhoneNumber: function () {
        const jsCharactersChange = $(".js-characters-change");
        if (jsCharactersChange.length === 0) return false;
        jsCharactersChange.on('blur', function () {
            const target = $(this);
            const val = target.val();
            const han = val.replace(/[Ａ-Ｚａ-ｚ０-９－]/g, function (s) { return String.fromCharCode(s.charCodeAt(0) - 0xFEE0) }).replace(/[-－―]/g, "");

            if (val.match(/[Ａ-Ｚａ-ｚ０-９－]/g)) {
                $(this).val(han);
            }
        });
    },
    contactFormValidate: function (element) {
        const target = $(`#${element}`);
        if (target.length === 0) return false;

        const rules = {
            customer_name: { required: true },
            zip: {
                postalCode: true,
                required: true
            },
            customer_address: { required: true },
            customer_phone: {
                tel: true,
                required: true,
                isNum: true,
                minlength: 10,
            },
        }
        const messages = {
            customer_name: { required: "*名前が入力されていません" },
            zip: {
                required: "*郵便番号が入力されていません",
                isNum: "*郵便番号は半角数字で入力してください",
                minlength: "*郵便番号は7文字以上で入力してください",
                postalCode: "*郵便番号は半角数字のみで入力してください",
            },
            customer_address: { required: "*住所が入力されていません" },
            customer_phone: {
                tel: "電話番号を正しく入力してください。ハイフンや記号は不要です。有効な市外局番を使用してください。",
                required: "*電話番号が入力されていません",
                isNum: "*電話番号は半角数字で入力してください",
                minlength: "*電話番号は市外局番から10文字以上で入力してください",
            },
        }

        const methods = {
            tel: function (value, element) {
                return this.optional(element) || /^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/.test(value.replace(/[━.*‐.*―.*－.*\-.*ー.*\-]/gi, ''));
            },
            postalCode: function (value, element) {
                return this.optional(element) || /^\d{7}$/.test(value);
            }
        };

        $.each(methods, function (key) {
            $.validator.addMethod(key, this);
        });

        target.validate({
            rules: rules,
            messages: messages,
            errorElement: "p",
            errorClass: "alert",
            errorPlacement: function (error, element) {
                error.insertAfter($('.' + element.attr('name') + '_msg'));
            },
        });
    },
    phoneNumberCorrectValue: function () {
        const charactersChange = $('.js-characters-change');
        if (charactersChange.length === 0) return false;
        jQuery.validator.addMethod("isNum", function (value, element) {
            return this.optional(element) || /^[0-9０-９]+[0-9０-９-]+[0-9０-９]$/.test(value);
        }, '番号を入力してください');
    },
    requestTextareaMessage: function () {
        const textareaMessage = $('.textareaMessage');

        if (textareaMessage.length === 0) return false;

        textareaMessage.on('change', function () {
            const targetText = $(this).val();
            const submitFormBtn = $('[data-btn="' + $(this).data('formtype') + '"]');

            function containsNoJapanese(string) {
                if (!string || string.trim() === "") {
                    return false;
                }

                const pattern = /[一-龯ぁ-んァ-ヶーｧ-ﾝﾞﾟ]/;

                return !pattern.test(string);
            }

            if (containsNoJapanese(targetText)) {
                submitFormBtn.prop("disabled", true);
            } else {
                submitFormBtn.prop("disabled", false);
            }

        });
    },
    requestMethodTabAction: function () {
        let requestMethodTab = $("#jsTabRequest .jsTabBtn");
        if (requestMethodTab.length > 0) {
            requestMethodTab.on('click', function () {
                var index = requestMethodTab.index(this);
                $("#jsTabRequest .jsTabBtn, #jsTabRequest .jsTabPanel").removeClass("is-active");
                $(this).addClass("is-active");
                $("#jsTabRequest .jsTabPanel").eq(index).addClass("is-active");
            });
        }
    },
    requestZipCodeValidate: function () {
        const targetInputZip = $('input[name="zip"]');
        if (targetInputZip.length === 0) return false;
        targetInputZip.on('change', function () {

            const targetInput = $(this);
            let postZipCode = targetInput.val().trim();

            $.ajax({
                type: 'GET',
                url: "https://zipcloud.ibsnet.co.jp/api/search",
                data: { zipcode: postZipCode },
                dataType: 'json'
            }).done(function (data) {
                if (data.results && data.results.length > 0) {
                    const address = data.results[0].address1 + data.results[0].address2;
                    console.log("取得した住所:", address);
                    targetInput.parent().children('.is-zipCodeAlert').remove();
                } else {
                    console.log("該当する住所が見つかりませんでした。");
                    console.log(targetInput);
                    if (targetInput.parent().children('.is-zipCodeAlert').length === 0) {
                        targetInput.parent().append('<p class="cap is-zipCodeAlert">*該当する住所が見つかりませんでした。</p>');
                    }
                }
            }).fail(function (error) {
                console.error("通信エラーが発生しました:", error);
            })
        });
    },
    checkHoneypot: function (fromType) {
        fromType.on('submit', function (event) {
            fromType.find(':input').each(function () {
                const name = $(this).attr('name');
                const value = $(this).val();
                if (name === 'honeypot') {
                    if (value !== '') {
                        event.preventDefault();
                        alert("送信に失敗しました。");
                        return false;
                    }
                    return true;
                }
            });
        });
    },
    calendarDatepicker: function () {
        if ($(".msbs_calendar").length === 0) return false;

        if ($(".reservation_calendar").length > 0) {
            $('.reservation_calendar').datepicker({
                altField: '#result',  // 入力結果の表示箇所
                altFormat: "yy/mm/dd(D)",  // 入力結果の表示方法 Dは曜日の省略形、DDは省略なし
                currentText: "Now",  // Todayボタンテキストを置換
                dateFormat: "yy/mm/dd(D)",  // 2021/08/27(Fri)
                defaultDate: 2,  // 2日後をハイライト
                duration: 100,  // 500ミリ秒かけて表示
                firstDay: 1,  // 週を月曜日から始める
                selectOtherMonths: true,  // 当月前後の日付選択可能
                showAnim: 'slideDown',  // スライドダウンでカレンダー表示
                showButtonPanel: true,  // カレンダーにボタンを表示させるか
                showOtherMonths: true,  // 当月カレンダーに前後月の日を表示
                minDate: -1,  // 1日前から選択可能
                maxDate: '1M +20D',  // 1ヶ月と20日後まで選択可能 
            });
        };

        if ($(".recruitment_calendar").length > 0) {
            const currentYear = new Date().getFullYear();

            $('.recruitment_calendar').datepicker({
                altField: '#result',  // 入力結果の表示箇所
                altFormat: "yy/mm/dd(D)",  // 入力結果の表示方法 Dは曜日の省略形、DDは省略なし
                currentText: "Now",  // Todayボタンテキストを置換
                dateFormat: "yy/mm/dd(D)",  // 2021/08/27(Fri)
                // defaultDate: 2,  // 2日後をハイライト
                duration: 100,  // 500ミリ秒かけて表示
                firstDay: 1,  // 週を月曜日から始める
                // selectOtherMonths: true,  // 当月前後の日付選択可能
                showAnim: 'slideDown',  // スライドダウンでカレンダー表示
                showButtonPanel: true,  // カレンダーにボタンを表示させるか
                showOtherMonths: true,  // 当月カレンダーに前後月の日を表示
                changeYear: true,  // 当月カレンダーに前後月の日を表示
                changeMonth: true,  // 当月カレンダーに前後月の日を表示
                yearRange: `1950:${currentYear}`, // 年の範囲を1950年から現在の年に設定
                minDate: new Date(1950, 0, 1),    // 選択可能な最小日付
                maxDate: new Date(currentYear, 11, 31) // 選択可能な最大日付を現在の年の最後の日に設定
            });
        };
    },
    /* 採用ページ 募集要項モーダル */
    jobCategoryModal: function () {
        const jobItem = $(".recruitment-job-category__item");
        if (jobItem.length === 0) return false;

        for (let i = 0; i < jobItem.length; i++) {
            let modalBtn = $(jobItem[i]).children(".recruitment-job-category__button");
            let modalElem = $(jobItem[i]).children(".recruitment-job-category__modal");
            let modalClose = $(modalElem).find(".recruitment-job-category__modal-close");
            let applyBtn = $(modalElem).find(".recruitment-job-category__apply-btn");

            $(modalBtn).on("click", function() {
                modalElem.addClass("is-active");
                if (jQuery(window).width() <= 768) {
                    if (!$("body").hasClass("fix")) {
                        $("body").addClass("fix");
                    }
                }
            });

            $(modalClose).on("click", function () {
                if ($(modalElem).hasClass("is-active")) {
                    modalElem.removeClass("is-active");
                }
                if (jQuery(window).width() <= 768) {
                    if ($("body").hasClass("fix")) {
                        $("body").removeClass("fix");
                    }
                }
            });

            $(applyBtn).on("click", function () {
                if ($(modalElem).hasClass("is-active")) {
                    modalElem.removeClass("is-active");
                }
                if (jQuery(window).width() <= 768) {
                    if($("body").hasClass("fix")) {
                        $("body").removeClass("fix")
                    };
                }
            });
        }
    },

    /* 採用ページ用ナビ */
    recruitCtaNav: function () {
        if ($("#ctaRecruitMenu").length === 0) return false;

        // ヘッダー要素を取得
        const globalHeader = $("#header");
        // ヘッダーメニューが開いていた場合バッティングを避けなけらばならないため、ヘッダーメニューを閉じるアクションを登録
        const headerCloseAction = () => {
            // アクティブクラスが付与されたヘッダーのメニューボタンを取得
            const headerBtnActive = globalHeader.find(".nav_btn.active");
            if (headerBtnActive) {
                headerBtnActive.removeClass("active");
                const gnav = globalHeader.find(".nav_global_sp");
                gnav.css('display', 'none');
                if ($("body").hasClass("fix")) {
                    $("body").removeClass("fix");
                }
            }
        }

        // メニューを開いた時の動作
        $("#ctaRecruitMenu").on("click", function () {
            if (globalHeader) {
                headerCloseAction();
            }
            $(this).toggleClass("is-nav-open");
            $("#ctaRecruit").toggleClass("is-nav-open");
            $("#ctaRecruit").find("#ctaRecruitCatch,#ctaRecruitNav,.cta-recruit__menu-button-line").toggleClass("is-nav-open");

            if (jQuery(window).width() <= 768) {
                if ($(this).hasClass("is-nav-open") && !$("body").hasClass("fix")) {
                    $("body").addClass("fix");
                } else {
                    $("body").removeClass("fix");
                }
            }
        });

        // メニュー内のアンカーリンクを押した際のアクション
        $(".cta-recruit__nav-link").on("click", function () {
            if ($("#ctaRecruit").hasClass("is-nav-open")) {
                $("#ctaRecruit").removeClass("is-nav-open");
                $("#ctaRecruit").find("#ctaRecruitCatch,#ctaRecruitNav,#ctaRecruitMenu,.cta-recruit__menu-button-line").removeClass("is-nav-open");

                if (jQuery(window).width() <= 768) {
                    if ($("body").hasClass("fix")) {
                        $("body").removeClass("fix");
                    }
                }
            }
        });

        // 「募集職種」「応募する」ボタンが押された時のアクション
        $(".cta-recruit__button").on("click", function () {
            if (globalHeader) {
                headerCloseAction();
            }
            if (jQuery(window).width() <= 768) {
                if ($("body").hasClass("fix")) {
                    $("body").removeClass("fix");
                }
            }
            if ($("#ctaRecruit").hasClass("is-nav-open")) {
                $("#ctaRecruit").removeClass("is-nav-open");
                $("#ctaRecruit").find("#ctaRecruitCatch,#ctaRecruitNav,#ctaRecruitMenu,.cta-recruit__menu-button-line").removeClass("is-nav-open");
            }
        });
    }
};

jQuery(function ($) {
    // ページ内スクロールアクション
    Home.pageScrollLink();

    // ご葬儀の評判口コミページ 「さらに表示」ボタン
    Home.questionaryHiddenPost();

    // 都道府県ページ spアコーディオン
    Home.jsAreaAccordionTab();

    // 花祭壇スライダー
    Home.flowerAltarGallerySlider();

    // 斎場詳細ページ用のgoogleMapApiを表示する
    Home.postSearchGoogleMapPins();

    // 市区町村ページ用のgoogleMapApiを表示する
    Home.areaSearchGoogleMapPins();

    // 斎場を探す 市区町村リンクボックスのsummaryタグのアコーディオンを実装する
    Home.searchAreaSaijouAccordion();

    // ポップアップ Luminous（画像拡大） 複数画像
    Home.initializePhotoGallery('.photo-gallery');

    // ポップアップ Luminous（画像拡大） 単体画像
    Home.initializePhotoSingle('.photo-single');

    // sp ハンバーガーメニューの横線
    Home.headerHamburgerMenuAction();

    // もっと見るボタンのアクション
    Home.commonMorePostBtnAction();

    // 電話番号のフォーカスが外れた時に発火して、全角英数字や記号を半角に変換し、特定の記号（ハイフン）を削除
    Home.removeHyphenPhoneNumber();

    // お問い合わせフォーム バリデーション
    Home.contactFormValidate("msbs_request_post_form");
    Home.contactFormValidate("msbs_request_download_form");
    Home.contactFormValidate("msbs_counsel_form");

    // お問いわせフォーム ボット対策 ハニーポット
    Home.checkHoneypot($('#msbs_request_post_form'));
    Home.checkHoneypot($('#msbs_request_download_form'));
    Home.checkHoneypot($('#recruitment_request_post_form'));

    /**
     * 電話番号が正常に入力されたか検証
     * OK 090-1234-5678 09012345678
     * NG 03- 03a
    */
    Home.phoneNumberCorrectValue();

    // お問い合せフォーム「備考欄」「ご相談内容」の入力された文字列に日本語が無い場合送信を許可しない
    Home.requestTextareaMessage();

    // お問いわせフォーム 郵送とダウンロードの切り替え
    Home.requestMethodTabAction();

    // お問いわせフォーム 郵便番号のバリデーション
    Home.requestZipCodeValidate();

    // お問い合わせフォームで予約カレンダーを表示する
    Home.calendarDatepicker();

    // 採用ページ 募集要項モーダル
    Home.jobCategoryModal();

    // 採用ページ用ナビ
    Home.recruitCtaNav();

    if (Home.isMobile()) {
        /* モバイルナビ */
        $("#header .nav_btn").on("click", function () {
            $(".nav_global_sp").fadeToggle("fast").scrollTop(0);
            $(this).toggleClass("active");
            $("body").toggleClass("fix");
        });
        $(".nav_global_sp .nav .acc").on("click", function () {
            $(this).find("i").toggleClass("minus");
            $(this).next(".sub").slideToggle();
        });

        /* フッター */
        $("#footer .nav .sp").on("click", function () {
            $(this).toggleClass("minus");
            $(this).next(".sub").slideToggle();
        });
    } else {
        // pc、グローバルメニューhover時にcontainerにクラスを追加する
        Home.pcHeaderNavAction();
    }

    let startPos = 0;
    let winScrollTop = 0;
    $(window).on('scroll', function () {
        // 共通 フローティングCTA
        if ($(this).scrollTop() > 100) {
            $(".floatingCta").fadeIn();
        } else {
            $(".floatingCta").fadeOut();
        }

        // sp nav
        if ($(this).scrollTop() >= 25) {
            $("#header .nav_nav_sp").addClass("adjust");
            $("#header .nav_global_sp").css("top", "50px");
        } else {
            $("#header .nav_nav_sp").removeClass("adjust");
            $("#header .nav_global_sp").css("top", 75 - $(window).scrollTop() + "px");
        }

        // ヘッダー調整
        winScrollTop = $(this).scrollTop();
        if (winScrollTop >= startPos) {
            if (winScrollTop >= 120) {
                $("#header").addClass("hide");
            }
        } else {
            $("#header").removeClass("hide");
        }
        startPos = winScrollTop;
    });

    /* faq */
    $(".list_faq dt,.faq_q").on("click", function () {
        $(this).toggleClass("minus");
        $(this).next("dd,.faq_a").slideToggle("fast");
    });

    /*採用ページ用faq*/
    $(".faqRecruit").on("click", function () {
        $(this).find(".faqRecruitQuestion,.faqRecruitQuestionButton,.faqRecruitAnswer").toggleClass("is-active");
        $(this).find(".faqRecruitAnswer").slideToggle("fast");
    });

    $(".block_step .box .cts.acc .ttl_sub .sp").on("click", function () {
        $(this).toggleClass("open");
        $(this).parent(".ttl_sub").toggleClass("open");
        $(this).parent(".ttl_sub").next(".link_area").slideToggle();
    });

    // .list_category
    $(".list_category > li .ttl").on("click", function () {
        $(this).toggleClass("open");
        $(this).next(".list").slideToggle("fast");
    });

    // .list_about_staff_new
    $(".list_about_staff_new").each(function () {
        var num = $(this).find("li").length;
        if (num <= 2) {
            $(this).addClass("sp_center");
        }
        if (num <= 4) {
            $(this).addClass("pc_center");
        }
    });

    // プルダウンメニュー
    $(".custom-select").each(function () {
        var classes = $(this).attr("class"),
            id = $(this).attr("id"),
            name = $(this).attr("name");
        var template = '<div class="' + classes + '">';
        template +=
            '<span class="custom-select-trigger">' +
            $(this).attr("placeholder") +
            "</span>";
        template += '<div class="custom-options">';
        $(this)
            .find("option")
            .each(function () {
                template +=
                    '<span class="custom-option ' +
                    $(this).attr("class") +
                    '" data-value="' +
                    $(this).attr("value") +
                    '">' +
                    $(this).html() +
                    "</span>";
            });
        template += "</div></div>";

        $(this).wrap('<div class="custom-select-wrapper"></div>');
        $(this).hide();
        $(this).after(template);
    });

    $(".custom-option:first-of-type").hover(
        function () {
            $(this).parents(".custom-options").addClass("option-hover");
        },
        function () {
            $(this).parents(".custom-options").removeClass("option-hover");
        }
    );

    $(".custom-select-trigger").on("click", function (e) {
        $(this).toggleClass("open");
        $(this).parents(".custom-select").toggleClass("opened");
        e.stopPropagation();
    });

    $(".custom-option").on("click", function () {
        $(this)
            .parents(".custom-select")
            .find(".custom-select-trigger")
            .removeClass("open");
        $(this)
            .parents(".custom-select-wrapper")
            .find("select")
            .val($(this).data("value"));
        $(this)
            .parents(".custom-options")
            .find(".custom-option")
            .removeClass("selection");
        $(this).addClass("selection");
        $(this).parents(".custom-select").removeClass("opened");
        $(this)
            .parents(".custom-select")
            .find(".custom-select-trigger")
            .text($(this).text());
    });

    // モーダル
    /* 検索ページ */
    $(".btn_cancel").on('click', function () {
        parent.$.fn.colorbox.close();
        return false;
    });

    $(".inline").colorbox({
        inline: true,
        opacity: "0.75",
        closeButton: false,
        maxWidth: "100%",
        maxHeight: "100%",
        className: "modal01",
        initialWidth: "0",
        initialHeight: "0",
        transition: "fade",
        // speed: "0",
        fixed: true,
        // scrolling: false,
        // onOpen: function () {
        //   current_scrollY = jQuery(window).scrollTop();
        //   jQuery("#wrapper").css({
        //     position: "fixed",
        //     width: "100%",
        //     overflow: "auto",
        //     top: -1 * current_scrollY,
        //   });
        // },
        // onClosed: function () {
        //   jQuery("#wrapper").attr({ style: "" });
        //   jQuery("html, body").prop({ scrollTop: current_scrollY });
        // },
    });

    /* NCH */
    $(".btn_close").on('click', function () {
        parent.$.fn.colorbox.close();
        return false;
    });

    $(".inline_sanitation").colorbox({
        inline: true,
        opacity: "0.75",
        closeButton: false,
        maxWidth: "100%",
        maxHeight: "100%",
        className: "modal02",
        initialWidth: "0",
        initialHeight: "0",
        transition: "fade",
        // speed: "0",
        fixed: true,
        // scrolling: false,
        // onOpen: function () {
        //   current_scrollY = jQuery(window).scrollTop();
        //   jQuery("#wrapper").css({
        //     position: "fixed",
        //     width: "100%",
        //     overflow: "auto",
        //     top: -1 * current_scrollY,
        //   });
        // },
        // onClosed: function () {
        //   jQuery("#wrapper").attr({ style: "" });
        //   jQuery("html, body").prop({ scrollTop: current_scrollY });
        // },
    });

    /* answer */
    $(".cts_answer").colorbox({
        rel: "cts_answer",
        opacity: "0.75",
        closeButton: false,
        maxWidth: "840",
        maxHeight: "670",
        className: "modal03",
        // initialWidth: "0",
        // initialHeight: "0",
        // transition: "fade",
        current: "{current} / {total}",
        previous: "",
        next: "",
    });

    // 地域から葬儀場を探す
    $(".search_map .list > li").on('mouseover', function () {
        var className = $(this).attr("id");
        var className = "." + className;
        var className = ".search_map .map img" + className;
        $(className).addClass("fadein");
        $(this).addClass("open");
    }).on('mouseout', function () {
        $(".search_map .map img").removeClass("fadein");
    });

    $("#step1 .block_input input:checkbox").click(function () {
        n = $("#step1 .block_input input:checked").length;
        if (n > 0) {
            $(".belt_step").fadeIn();
            $(".btn_area .btn.disabled").prop("disabled", false);
        } else {
            $(".belt_step").fadeOut();
            $(".btn_area .btn.disabled").prop("disabled", true);
        }
    });

    n = $("#step1 .block_input input:checked").length;
    if (n > 0) {
        $(".btn_area .btn.disabled").prop("disabled", false);
    } else {
        $(".btn_area .btn.disabled").prop("disabled", true);
    }

    $("#step1_1 .block_input input:checkbox").click(function () {
        n = $("#step1_1 .block_input input:checked").length;
        if (n > 0) {
            $(".btn_area .btn.disabled.step1_1").prop("disabled", false);
        } else {
            $(".btn_area .btn.disabled.step1_1").prop("disabled", true);
        }
    });

    $("#step1_2 .block_input input:checkbox").click(function () {
        n = $("#step1_2 .block_input input:checked").length;
        if (n > 0) {
            $(".btn_area .btn.disabled.step1_2").prop("disabled", false);
        } else {
            $(".btn_area .btn.disabled.step1_2").prop("disabled", true);
        }
    });

    $("#step1_3 .block_input input:checkbox").click(function () {
        n = $("#step1_3 .block_input input:checked").length;
        if (n > 0) {
            $(".btn_area .btn.disabled.step1_3").prop("disabled", false);
        } else {
            $(".btn_area .btn.disabled.step1_3").prop("disabled", true);
        }
    });

    $("#step1_4 .block_input input:checkbox").click(function () {
        n = $("#step1_4 .block_input input:checked").length;
        if (n > 0) {
            $(".btn_area .btn.disabled.step1_4").prop("disabled", false);
        } else {
            $(".btn_area .btn.disabled.step1_4").prop("disabled", true);
        }
    });

    $(".block_input.acc .list_search dt .sp").on("click", function () {
        $(this).toggleClass("open");
        $(this).parent("dt").next("dd").slideToggle("fast");
    });

    $(".block_input.casestudy .wrap_select .btn_select").on("click", function () {
        $(this).toggleClass("open");
        $(this).next(".box").toggleClass("adjust").slideToggle("fast");
    });

    // .more
    var moreNum = 0;
    moreNum = 5;
    $(".more a").on("click", function () {
        $(".list_result > .list_result-item.is-hidden").slice(0, moreNum).removeClass("hidden");
        if ($(".list_result > list_result-item.is-hidden").length == 0) {
            $(".list_result").next(".more").hide();
        }
    });

    $("#listResultBtn").on("click", function () {
        $(".list_result > .list_result-item.is-hidden").slice(0, moreNum).removeClass("is-hidden");
        if ($(".list_result > list_result-item.is-hidden").length == 0) {
            $(".list_result").next("#listResultBtn").hide();
        }
    });

    moreNum = 12;
    if ($(".list_case_result > li").length <= 12) {
        $(".list_case_result").next(".more").hide();
    }
    $(".list_case_result li:nth-child(n + " + (moreNum + 1) + ")").addClass(
        "hidden"
    );
    $(".more a").on("click", function () {
        $(".list_case_result > li.hidden").slice(0, moreNum).removeClass("hidden");
        if ($(".list_case_result > li.hidden").length == 0) {
            $(".list_case_result").next(".more").hide();
        }
    });

    moreNum = 10;
    if ($(".list_cts > li").length <= 10) {
        $(".list_cts").next(".more").hide();
    }
    $(".list_cts li:nth-child(n + " + (moreNum + 1) + ")").addClass("hidden");
    $(".more a").on("click", function () {
        $(".list_cts > li.hidden").slice(0, moreNum).removeClass("hidden");
        if ($(".list_cts > li.hidden").length == 0) {
            $(".list_cts").next(".more").hide();
        }
    });

    moreNum = 12;
    if ($(".list_media > li").length <= 12) {
        $(".list_media").next(".more").hide();
    }
    $(".list_media li:nth-child(n + " + (moreNum + 1) + ")").addClass("hidden");
    $(".more a").on("click", function () {
        $(".list_media > li.hidden").slice(0, moreNum).removeClass("hidden");
        if ($(".list_media > li.hidden").length == 0) {
            $(".list_media").next(".more").hide();
        }
    });

    $(".tab_box .tab_btn").click(function () {
        var index = $(".tab_box .tab_btn").index(this);
        $(".tab_box .tab_btn, .tab_box .tab_panel").removeClass("active");
        $(this).addClass("active");
        $(".tab_box .tab_panel").eq(index).addClass("active");
    });

    // list_cts
    $(".list_cts .cts .ttl.date").parent(".cts").addClass("choku");
    $(".list_cts .cts .ttl.example").parent(".cts").addClass("choku");

    // 最初のアコーディオン要素を開く
    $(".js-accordion").first().parent().addClass("is-open");
    $(".js-accordion").first().next().show();

    $(".js-accordion").on("click", function (e) {
        e.preventDefault();

        // 他のすべてのアコーディオン要素を閉じる
        $(".js-accordion").not(this).parent().removeClass("is-open");
        $(".js-accordion").not(this).next().slideUp();

        // クリックされた要素の開閉を切り替える
        if ($(this).parent().hasClass("is-open")) {
            $(this).parent().removeClass("is-open");
            $(this).next().slideUp();
        } else {
            $(this).parent().addClass("is-open");
            $(this).next().slideDown();
        }
    });
});

jQuery(window).on('load', function ($) {

    jQuery("img.lazy").lazyload();

    // 画像切り替え
    let $elem = jQuery("img");
    let sp = "_sp.";
    let pc = "_pc.";
    let replaceWidth = 769;

    function imageSwitch() {
        var windowWidth = parseInt(jQuery(window).width());
        $elem.each(function () {
            var $this = jQuery(this);
            if (windowWidth >= replaceWidth) {
                $this.attr("src", $this.attr("src").replace(sp, pc));
            } else {
                $this.attr("src", $this.attr("src").replace(pc, sp));
            }
        });
    }

    imageSwitch();
    var resizeTimer;
    jQuery(window).on("resize", function () {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
            imageSwitch();
        }, 0);
    });

    // .img_areaemo
    jQuery(".img_areaemo .img").each(function () {
        var num = jQuery(this).find("img, iframe").length;
        if (num == 1) {
            jQuery(this).addClass("type01");
        }
        if (num == 2) {
            jQuery(this).addClass("type02");
        }
        if (num == 3) {
            jQuery(this).addClass("type03");
        }
        if (num >= 4) {
            jQuery(this).addClass("type02");
        }
    });

    // 花祭壇スライダー
    jQuery(".gallerySlider .thumbnail_slider").on("init", function (event, slick) {
        jQuery(this).prepend('<div class="slick-num"><span class="now-count"></span> / <span class="all-count"></span></div>');
        jQuery(".now-count").text(slick.currentSlide + 1);
        jQuery(".all-count").text(slick.slideCount);
    }).slick({
        slidesToShow: 7,
        asNavFor: ".main_slider",
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 4,
                },
            },
        ],
        prevArrow: '<button class="slide-arrow prev-arrow"></button>',
        nextArrow: '<button class="slide-arrow next-arrow"></button>'
    }).on("beforeChange", function (event, slick, currentSlide, nextSlide) {
        jQuery(".now-count").text(nextSlide + 1);
    });

    jQuery(".gallerySlider .main_slider").slick({
        autoplay: true,
        asNavFor: ".thumbnail_slider",
    });

    jQuery(".gallerySlider .thumbnail_slider").on("init", function (event, slick) {
        jQuery(this).prepend('<div class="slick-num"><span class="now-count"></span> / <span class="all-count"></span></div>');
        jQuery(".now-count").text(slick.currentSlide + 1);
        jQuery(".all-count").text(slick.slideCount);
    }).slick({
        slidesToShow: 7,
        asNavFor: ".main_slider",
        focusOnSelect: true,
        responsive: [{
            breakpoint: 769,
            settings: {
                slidesToShow: 4,
            },
        },],
        prevArrow: '<button class="slide-arrow prev-arrow"></button>',
        nextArrow: '<button class="slide-arrow next-arrow"></button>'
    }).on("beforeChange", function (event, slick, currentSlide, nextSlide) {
        jQuery(".now-count").text(nextSlide + 1);
    });

    jQuery(".gallerySlider .main_slider").slick({
        autoplay: true,
        asNavFor: ".thumbnail_slider",
    });

    jQuery('.slider-lp').slick({
        autoplaySpeed: 0,
        speed: 10000,
        autoplay: true,
        Infinity: true,
        slidesToShow: 2,
        slideToscroll: 1,
        arrows: true,
        cssEase: "linear",
        swipe: false,
    });

    jQuery('#sliderFuneralHall').slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        arrows: true,
        prevArrow: '<button class="slide-arrow prev-arrow"></button>',
        nextArrow: '<button class="slide-arrow next-arrow"></button>',
        responsive: [{
            breakpoint: 769,
            settings: {
                centerMode: true,
                slidesToShow: 1,
            },
        },],
    });

    jQuery('#sliderFuneralHall-tokyo-area').slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: '<button class="slide-arrow prev-arrow"></button>',
        nextArrow: '<button class="slide-arrow next-arrow"></button>',
        responsive: [{
            breakpoint: 769,
            settings: {
                centerMode: true,
                slidesToShow: 1,
            },
        },],
    });

    jQuery('#sliderFuneralHall-chiba-area').slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: '<button class="slide-arrow prev-arrow"></button>',
        nextArrow: '<button class="slide-arrow next-arrow"></button>',
        responsive: [{
            breakpoint: 769,
            settings: {
                centerMode: true,
                slidesToShow: 1,
            },
        },],
    });

    jQuery('#sliderFuneralHall-kanagawa-area').slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: '<button class="slide-arrow prev-arrow"></button>',
        nextArrow: '<button class="slide-arrow next-arrow"></button>',
        responsive: [{
            breakpoint: 769,
            settings: {
                centerMode: true,
                slidesToShow: 1,
            },
        },],
    });

    jQuery('#sliderFuneralHall-saitama-area').slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: '<button class="slide-arrow prev-arrow"></button>',
        nextArrow: '<button class="slide-arrow next-arrow"></button>',
        responsive: [{
            breakpoint: 769,
            settings: {
                centerMode: true,
                slidesToShow: 1,
            },
        },],
    });

    jQuery(".btn_next").on("click", function () {
        var winW = jQuery(window).innerWidth();
        var nowSc = jQuery(".silde_img").scrollLeft();
        var nextSc = nowSc + winW;
        jQuery(".silde_img").animate({ scrollLeft: nextSc }, 400);
    });

    jQuery(".btn_prev").on("click", function () {
        var winW = jQuery(window).innerWidth();
        var nowSc = jQuery(".silde_img").scrollLeft();
        var nextSc = nowSc - winW;
        jQuery(".silde_img").animate({ scrollLeft: nextSc }, 400);
    });

    let maxHeight = 0;
    jQuery(".list_hall_slider .link").each(function (idx, elem) {
        let height = jQuery(elem).height();
        if (maxHeight < height) {
            maxHeight = height;
        }
    });
    jQuery(".list_hall_slider .link").height(maxHeight);

    maxHeight = 0;
    jQuery(".list_case_slider.list_case .cts a").each(function (idx, elem) {
        height = jQuery(elem).height();
        if (maxHeight < height) {
            maxHeight = height;
        }
    });
    jQuery(".list_case_slider.list_case .cts a").height(maxHeight);

    maxHeight = 0;
    jQuery(".list_episode_slider.list_case_episode .cts a").each(function (idx, elem) {
        height = jQuery(elem).height();
        if (maxHeight < height) {
            maxHeight = height;
        }
    });
    jQuery(".list_episode_slider.list_case_episode .cts a").height(maxHeight);

    maxHeight = 0;
    jQuery(".list_new_slider.slider01 .cts a").each(function (idx, elem) {
        height = jQuery(elem).height();
        if (maxHeight < height) {
            maxHeight = height;
        }
    });
    jQuery(".list_new_slider.slider01 .cts a").height(maxHeight);

    jQuery(".list_halltop_slider").slick({
        fade: true,
    });

    jQuery(".list_nch_slider").slick({
        fade: true,
    });

    jQuery(".list_lp_slider").slick({
        autoplay: true,
        autoplaySpeed: 0,
        speed: 3000,
        cssEase: "linear",
        swipe: false,
        arrows: false,
        pauseOnFocus: false,
        pauseOnHover: false,
        variableWidth: true,
    });

    jQuery(".list_hall_slider").slick({
        // centerMode: true,
        // centerPadding: '20px',
        slidesToShow: 4,
        // prevArrow: '',
        // nextArrow: '',
        // fade: false,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    centerMode: true,
                    // centerPadding: '0',
                    slidesToShow: 1,
                },
            },
        ],
    });

    jQuery(".recruitment-sns__media-list").slick({
        autoplay: true,
        autoplaySpeed: 0,
        speed: 3000,
        cssEase: "linear",
        swipe: false,
        arrows: false,
        pauseOnFocus: false,
        pauseOnHover: false,
        variableWidth: true,
    });

    function sliderSetting() {
        var width = jQuery(window).width();
        if (width < 769) {
            jQuery(".list_planoption .first").prependTo(".list_planoption.main");
            jQuery(".list_planoption_slider").not(".slick-initialized").slick({
                autoplay: true,
                centerMode: false,
                arrows: true,
                infinite: true,
                dots: true,
                centerMode: true,
                centerPadding: "13.5%",
                slidesToShow: 1,
            });
        } else {
            jQuery(".list_planoption_slider.slick-initialized").slick("unslick");
            jQuery(".list_planoption.main .first").prependTo(".list_planoption_slider");
        }
    }
    sliderSetting();
    jQuery(window).resize(function () {
        sliderSetting();
    });

    function sliderSetting() {
        var width = jQuery(window).width();
        if (width < 769) {
            jQuery(".list_case .first").prependTo(".list_case.main");
            jQuery(".list_case_slider").not(".slick-initialized").slick({
                autoplay: true,
                // centerMode: false,
                arrows: false,
                infinite: true,
                dots: true,
                centerMode: true,
                centerPadding: "14%",
                slidesToShow: 1,
            });
        } else {
            jQuery(".list_case_slider.slick-initialized").slick("unslick");
            jQuery(".list_case.main .first").prependTo(".list_case_slider");
        }
    }
    sliderSetting();
    jQuery(window).resize(function () {
        sliderSetting();
    });

    function sliderSetting() {
        var width = jQuery(window).width();
        if (width < 769) {
            jQuery(".list_case_episode .first").prependTo(".list_case_episode.main");
        } else {
            jQuery(".list_case_episode.main .first").prependTo(".list_episode_slider");
        }
    }
    sliderSetting();
    jQuery(window).resize(function () {
        sliderSetting();
    });

    jQuery(".list_episode_slider").slick({
        autoplay: true,
        centerMode: false,
        infinite: true,
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    autoplay: true,
                    infinite: true,
                    dots: true,
                    centerMode: true,
                    centerPadding: "14%",
                    slidesToShow: 1,
                },
            },
        ],
    });

    jQuery(".list_new_slider").slick({
        autoplay: true,
        centerMode: false,
        infinite: true,
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    autoplay: true,
                    infinite: true,
                    centerMode: true,
                    centerPadding: "13%",
                    slidesToShow: 1,
                },
            },
        ],
    });

    jQuery(".list_staff_slider").slick({
        // autoplay: true,
        centerMode: false,
        infinite: true,
        slidesToShow: 5,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    autoplay: true,
                    infinite: true,
                    centerMode: true,
                    centerPadding: "25%",
                    slidesToShow: 1,
                },
            },
        ],
    });

    jQuery(".list_answer").slick({
        // autoplay: true,
        centerMode: false,
        infinite: false,
        slidesToShow: 4,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    // autoplay: true,
                    // centerMode: false,
                    // arrows: false,
                    // infinite: true,
                    // dots: true,
                    centerMode: true,
                    centerPadding: "13%",
                    slidesToShow: 1,
                },
            },
        ],
    });

});

jQuery(window).on("load resize", function ($) {

    // .silde_img
    var winW = jQuery(window).innerWidth();
    var imgW = jQuery(".silde_img .scroll").width();
    var location = imgW - winW;
    jQuery(".silde_img").scrollLeft();

    jQuery(".silde_img").scroll(function () {
        if (jQuery(this).scrollLeft() > 1) {
            jQuery(".btn_prev").addClass("on");
        } else if (jQuery(this).scrollLeft() === 0) {
            jQuery(".btn_prev").removeClass("on");
        }

        if (jQuery(this).scrollLeft() === location) {
            jQuery(".btn_next").removeClass("on");
        } else if (jQuery(this).scrollLeft() < location) {
            jQuery(".btn_next").addClass("on");
        }
    });

    /* 検索 */
    let w = jQuery(window).width();
    let x = 768;
    if (w <= x) {
        jQuery(".search_block .txt").attr("placeholder", "住所・斎場名など");
        jQuery(".search_block .txt.casestudy").attr(
            "placeholder",
            "斎場名・市区町村を入力"
        );
    } else {
        jQuery(".search_block .txt").attr(
            "placeholder",
            "住所・斎場名などを入力してください"
        );
        jQuery(".search_block .txt.casestudy").attr(
            "placeholder",
            "斎場名・市区町村を入力してください"
        );
    }

    if (w >= x) {
        let maxHeight = 0;
        jQuery(".list_category > li .ttl").each(function (idx, elem) {
            let height = jQuery(elem).height();
            if (maxHeight < height) {
                maxHeight = height;
            }
        });
        jQuery(".list_category > li .ttl").height(maxHeight);
    } else if (w < x) {
        jQuery(".list_category > li .ttl").height("auto");
    }
});

const areaMovieObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
        const movie = entry.target;

        if (entry.isIntersecting) {
            movie.play();
        } else {
            movie.pause();
            movie.currentTime = 0;
        }
    });
}, { root: null, rootMargin: "0px", threshold: 0.5, });

const movies = document.querySelectorAll(".movie__img");
movies.forEach((movie) => {
    areaMovieObserver.observe(movie);
});

// service==============================
if (jQuery('.service-bottom__swiper').length > 0) {
    const serviceSwiper = new Swiper(".service-bottom__swiper", {
        // swiperの名前
        // 切り替えのモーション
        speed: 1000, // 表示切り替えのスピード
        effect: "slide", // 切り替えのmotion (※1)
        // allowTouchMove: true,
        // autoHeight: true,

        // 最後→最初に戻るループ再生を有効に
        loop: true,

        // 自動スライドについて
        autoplay: {
            delay: 7000,
        },
        // 表示について
        centeredSlides: true,
        slidesPerView: 1.2,
        spaceBetween: 11,
    });
}


// // review===============================
$(function () {
    $(".more-link-review").each(function (index) {
        $(this).after('<div class="more-link-review-after"></div>');
        f_lessTextReview(index);
    });
});

window.f_moreTextReview = function (index) {
    var e_text = $(".more-link-review").eq(index);
    var v_closeHeight = e_text.height();
    var v_openHeight = e_text.css("height", "auto").height(); // 修正: 正しい変数名
    e_text.height(v_closeHeight).animate({
        height: v_openHeight
    }, 150);

    $(".more-link-review-after:eq(" + index + ")").html(
        '<a href="javascript:void(0)" onclick="f_lessTextReview(' +
        index +
        ')">閉じる</a>'
    );
}

window.f_lessTextReview = function (index) {
    var e_more = $(".more-link-review:eq(" + index + ")");
    var e_moreAfter = $(".more-link-review-after:eq(" + index + ")");

    var v_max = 4;
    if (e_more.data("max")) {
        v_max = e_more.data("max");
    }

    var v_fontHeight = parseFloat(e_more.css("line-height"));
    if (!v_fontHeight) {
        v_fontHeight = parseFloat(e_more.css("font-size")) * 1;
    }

    var v_moreMaxHeight = v_fontHeight * v_max; // 4行分の高さを計算
    var v_textHeight = e_more[0].scrollHeight; // 全文の高さを取得

    if (v_textHeight > v_moreMaxHeight) {
        // 修正: 全文が4行を超える場合
        e_more.css({
            overflow: "hidden",
            height: v_moreMaxHeight + "px", // 4行分の高さに設定
            transition: "height 0.3s ease", // アニメーションを適用
        });

        e_moreAfter.css({
            "font-size": e_more.css("font-size"),
            "line-height": e_more.css("line-height"),
        });

        // "続きを読む" リンクを追加
        e_moreAfter.html(
            '<div><a href="javascript:void(0)" onclick="f_moreTextReview(' +
            index +
            ')">…続きを読む</a></div>'
        );
    } else {
        // テキストが4行以下ならリンクを表示しない
        e_moreAfter.html("");
    }
}

// 320px以下縮小=================================
!(function () {
    const viewport = document.querySelector('meta[name="viewport"]');

    function switchViewport() {
        const value =
            window.outerWidth > 320 ?
                "width=device-width,initial-scale=1" :
                "width=320";
        if (viewport.getAttribute("content") !== value) {
            viewport.setAttribute("content", value);
        }
    }
    addEventListener("resize", switchViewport, false);
    switchViewport();
})();